.courses-page header {
  background-image: linear-gradient(
      rgba(5, 10, 35, 0.75),
      rgba(5, 10, 35, 0.75)
    ),
    url(../../utils/programs.jpg);
}

.courses-page .card-img-overlay {
  background-color: rgba(5, 10, 35, 0.7);
}

.courses-page .card:hover {
  cursor: pointer;
}
