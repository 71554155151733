.home-page header {
  background-image: linear-gradient(
      rgba(5, 10, 35, 0.75),
      rgba(5, 10, 35, 0.75)
    ),
    url(../../utils/hero.jpg);
}

header {
  background-size: cover;
  background-position: center;
}

.blog-section {
  background-image: linear-gradient(
      rgba(5, 10, 35, 0.75),
      rgba(5, 10, 35, 0.75)
    ),
    url(../../utils/35db9721-36c7-4a5a-bec6-9c40a8cddb42.JPG);
  background-size: cover;
  background-position: center;
}
