.contact-page header {
  background-image: linear-gradient(
      rgba(5, 10, 35, 0.75),
      rgba(5, 10, 35, 0.75)
    ),
    url(../../utils/contact.jpg);
}

#contact-form {
  width: 50%;
}

#contact-form .form-control:focus,
#contact-form .form-select:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

@media only screen and (max-width: 992px) {
  #contact-form {
    width: 75%;
  }
}

@media only screen and (max-width: 576px) {
  #contact-form {
    width: 100%;
  }
}
