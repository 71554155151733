.App {
  text-align: center;
}

:root {
  line-height: 1.5;
  font-weight: 400;
  color-scheme: light dark;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
body {
  overflow-x: hidden;
}
h1,
h2,
h3,
p,
a {
  font-family: "Montserrat", sans-serif;
}

header h1 {
  font-size: 4rem;
}

.navbar .nav-link {
  font-size: 14px;
  color: #000;
}

.nav-sticky {
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 999;
}

.navbar {
  height: 100%;
  background: #fff !important;
  color: black;
  z-index: 9999;
}

nav .navbar-toggler:focus {
  box-shadow: none;
}

.scale-hover-effect {
  transition: transform 0.5s ease;
}

.scale-hover-effect:hover {
  transform: scale(1.05);
}

.height-75 {
  height: 75vh;
}

.footer-social-icons li:hover svg {
  fill: #212529;
}

.footer-navigation li:hover {
  color: #212529;
}

@media only screen and (max-width: 992px) {
  #basic-navbar-nav {
    background-color: #ffffff;
    padding: 10px 20px;
    border-radius: 0.375rem;
  }

  .navbar .nav-link {
    color: #212529;
  }
}

@media only screen and (max-width: 768px) {
  header h1 {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 576px) {
  header h1 {
    font-size: 2rem;
  }
}

::-webkit-scrollbar {
  background-color: #fff;
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #000;
}
::-webkit-scrollbar-track {
  background-color: #fff;
}
/* button {
  background-color: #8baae4 !important;
  border: 1px solid #5a709a !important;
} */
